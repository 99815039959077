import React from "react";

import { Box, Link, Toolbar, Typography } from "@material-ui/core/";

function Footer() {
  return (
    <Box component="footer" className="row footer">
      <Toolbar className="footerInner" p={0}>
        <Box>
          <Typography variant="body2">&copy; 2024<a href="mailto:paul@tuneful.ly">Tunefully</a></Typography>
        </Box>
        <Box>
          <Link href="/privacy">Privacy</Link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link href="/faq">FAQ</Link>
        </Box>
      </Toolbar>
    </Box>
  );
}

export default Footer;
