import React from "react";
import { Box, CircularProgress } from "@material-ui/core/";

const Spinner = () => {
  return (
    <Box className="activityWrapperComponentProgress">
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
