import React from "react";
import { Box } from "@material-ui/core/";

import Spinner from "../components/Spinner.js";

function Loading() {
  return (
    <Box className="pageWrapperOuter">
      <Box component="header" className="row header"></Box>

      <Box className="row content">
        <Box className="loadingInner">
          <Box className="centerContent">
            <Spinner />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Loading;
