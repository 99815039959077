export default [
  {
    id: 0,
    name: "Prep test",
    shortName: "prep"
  },
  {
    id: 1,
    name: "One",
    shortName: "1"
  },
  {
    id: 2,
    name: "Two",
    shortName: "2"
  },
  {
    id: 3,
    name: "Three",
    shortName: "3"
  },
  {
    id: 4,
    name: "Four",
    shortName: "4"
  },
  {
    id: 5,
    name: "Five",
    shortName: "5"
  },
  {
    id: 6,
    name: "Six",
    shortName: "6"
  },
  {
    id: 7,
    name: "Seven",
    shortName: "7"
  },
  {
    id: 8,
    name: "Eight",
    shortName: "8"
  }
];
