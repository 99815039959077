import React from "react";
import { Typography } from "@material-ui/core/";

import TextPageWrapper from "../components/TextPageWrapper.js";

function FAQ() {
  return (
    <TextPageWrapper>
        <div className="faq">
          <div className="question header">
            <Typography variant="h5">FAQ</Typography>
          </div>
          <div className="question">
            <Typography variant="h6">What is Tunefully?</Typography>
            <Typography variant="body1" gutterBottom>It's a site to help you pass your music grades. You can practice your scales and arpeggios, improve your sight reading with Rhythm Reading, learn with our quizzes and sing along with Sight Singing.</Typography>
          </div>
          <div className="question">
            <Typography variant="h6">I can't see the instrument I play. Where is it?</Typography>
            <Typography variant="body1" gutterBottom><a href="mailto:paul@tuneful.ly">Contact us</a> and we'll see if we can add it!</Typography>
          </div>
          <div className="question">
            <Typography variant="h6">You got something wrong!</Typography>
            <Typography variant="body1" gutterBottom>It's still in beta, if you see something wrong please let us know!</Typography>
          </div>
          <div className="question">
            <Typography variant="h6">Is there any advertising on the site?</Typography>
            <Typography variant="body1" gutterBottom>No, and we very much hope to keep it that way.</Typography>
          </div>
          <div className="question">
            <Typography variant="h6">Do you track my information?</Typography>
            <Typography variant="body1" gutterBottom>No, the site contains no tracking code.</Typography>
          </div>
          <div className="question">
            <Typography variant="h6">How was Tunefully made?</Typography>
            <Typography variant="body1" gutterBottom>With React, AWS, Python, <a href="https://www.vexflow.com/">Vexflow</a> and Musyngkite.</Typography>
          </div>
        </div>
    </TextPageWrapper>
  );
}

export default FAQ;
