import React from "react";

import { Typography, Box } from "@material-ui/core/";

import { useHistory } from "react-router-dom";

import TitleBarWrapper from "./TitleBarWrapper";
import Logo from "./Logo";

import {
  getChosenInstrument,
  getChosenGrade,
  removeChosenInstrument,
  removeChosenGrade
} from "../utils/choices.js";

function TitleBar() {
  const history = useHistory();

  const instrument = getChosenInstrument();
  const grade = getChosenGrade();

  const reRegister = () => {
    removeChosenInstrument(null);
    removeChosenGrade(null);

    history.push("/register");
  };

  // @todo - the margin-top on the menu item seems like a hack. Also should probably be em or somesuch
  return (
    <TitleBarWrapper>
      <Box className="titleBarOuter">
        <Box display="flex">
          <Logo />
        </Box>
        {instrument && grade && (
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="h6" color="inherit">
              {instrument.nicerName ? instrument.nicerName : instrument.name},{" "}
              {grade.shortName}
            </Typography>
            <div
              className="menuIcon"
              onClick={reRegister}
            >Change</div>
          </Box>
        )}
      </Box>
    </TitleBarWrapper>
  );
}

export default TitleBar;
