import React, { Suspense, lazy } from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core/";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home.js";
import Loading from "./pages/Loading.js";
import Register from "./pages/Register.js";
import Study from "./pages/Study";
import FAQ from "./pages/FAQ.js";
import Privacy from "./pages/Privacy.js";

import "fontsource-roboto";
import { createMuiTheme } from "@material-ui/core/styles";

const Rhythm = lazy(() => import("./pages/Rhythm.js"));
const Scales = lazy(() => import("./pages/Scales.js"));
const Singing = lazy(() => import("./pages/Singing.js"));
const Quiz = lazy(() => import("./pages/Quiz.js"));

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2e7d32"
      },
      secondary: {
        main: "#0097a7"
      }
    }
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/register" component={Register} />
              <Route path="/study" component={Study} />
              <Route path="/scales/:instrumentName/:grade" component={Scales} />
              <Route path="/rhythm/:instrumentName/:grade" component={Rhythm} />
              <Route
                path="/singing/:instrumentName/:grade"
                component={Singing}
              />
              <Route path="/quiz/:instrumentName/:grade" component={Quiz} />
              <Route path="/faq" component={FAQ} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/" component={Home} />
            </Switch>
          </Suspense>
        </Router>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
