import React from "react";
import { Box } from "@material-ui/core/";

import StartTitleBar from "./StartTitleBar.js";
import TitleBar from "./TitleBar.js";
import Footer from "./Footer.js";

function PageWrapper({ showMenus, children }) {
  return (
    <Box className="pageWrapperOuter">
      {showMenus ? <TitleBar /> : <StartTitleBar />}
      <Box className="row content">
        <Box className="pageWrapperInner">{children}</Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default PageWrapper;
