import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import {
  getChosenInstrument,
  getChosenGrade,
  setChosenInstrument,
  setChosenGrade
} from "../utils/choices.js";

import PageWrapper from "../components/PageWrapper.js";
import ChooseInstrument from "../components/ChooseInstrument.js";
import ChooseGrade from "../components/ChooseGrade.js";

function Register() {
  const history = useHistory();

  const [instrument, setInstrument] = useState(null);
  const [, setGrade] = useState(null);

  useEffect(() => {
    if (getChosenInstrument() && getChosenGrade()) {
      history.push("/study");
    }
  }, [history]);

  const chooseInstrument = instrument => {
    setInstrument(instrument);
  };

  const chooseGrade = event => {
    setGrade(event.currentTarget.value);

    setChosenInstrument(instrument);
    setChosenGrade(event.currentTarget.value);

    history.push("/study");
  };

  return (
    <PageWrapper>
      {!instrument && (
        <ChooseInstrument
          chooseInstrument={chooseInstrument}
        ></ChooseInstrument>
      )}
      {instrument && (
        <ChooseGrade
          instrument={instrument}
          chooseGrade={chooseGrade}
        ></ChooseGrade>
      )}
    </PageWrapper>
  );
}

export default Register;
