import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { Box, Button, Popover, Typography } from "@material-ui/core/";
import InfoIcon from '@material-ui/icons/Info';

import PageWrapper from "../components/PageWrapper.js";

import { getChosenInstrument, getChosenGrade } from "../utils/choices.js";

const copies = {
  "1": "Asks you to play scales and arpeggios like in the exam.",
  "2": "Practice sight reading and improve your rhythm. Tap along to the score, then press Play to see how you did.",
  "3": "Test your knowledge of musical symbols.",
  "4": "Practice sight singing. Sing along to the score, then press Play to see how you did.",
}

function Study() {
  const history = useHistory();
  const [copy, setCopy] = useState('')

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, id) => {
    setCopy(copies[id]);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const selectStudy = event => {
    if (!getChosenInstrument().id) {
      history.push(`/`);  
    } else {
      history.push(
        `/${event.currentTarget.value}/${getChosenInstrument().urlName}/${
          getChosenGrade().id
        }`
      );
    }
  };

  let buttons = [
    { id: "1", name: "Scales and Arpeggios", url: "scales" },
    { id: "2", name: "Rhythm Sight Reading", url: "rhythm" },
    { id: "3", name: "Quiz", url: "quiz" },
    { id: "4", name: "Sight Singing", url: "singing" }
  ]

  const buttonComponents = buttons.map(button => {
    return (
      <Box key={button.name}>
        <div><InfoIcon onClick={(event) => handleClick(event, button.id)} className="infoIcon" /></div>
        <Button
          className="buttonTask"
          variant="outlined"
          color="primary"
          value={button.url}
          onClick={selectStudy}
        >
          {button.name}
        </Button>
      </Box>
    )
  });

  return (
    <PageWrapper showMenus={true}>
      <Box className="centerTitle" m={2}>
        <Typography variant="h6">
          What would you like to practice today?
        </Typography>
      </Box>
      <Box className="studyButtons" component="nav">
        {buttonComponents}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        className="taskPopover"
      >
      <Typography sx={{ p: 2 }} className="taskPopoverCopy">{copy}</Typography>
    </Popover>
    </PageWrapper>
  );
}

export default Study;
