import React from "react";
import { Link, Typography } from "@material-ui/core/";

function Logo() {
  return (
    <React.Fragment>
      <Typography variant="h6" color="inherit" noWrap>
        <Link href="/" className="logoLink">
          Tuneful.ly
        </Link>
      </Typography>
      <Typography
        variant="subtitle2"
        color="inherit"
        noWrap
        className="logoSubscript"
      >
        BETA
      </Typography>
    </React.Fragment>
  );
}

export default Logo;
