export const STAVE_WIDTH = 640;
export const STAVE_HEIGHT = 140;
export const CLEF_TIME_WIDTH = 32;

export const TREBLE_CLEF = "treble";
export const BASS_CLEF = "bass";

// The widths of key signature as displayed on the stave
export const KEY_WIDTHS = {
  Cb: 69,
  Gb: 59,
  Db: 49,
  Ab: 40,
  Eb: 30,
  Bb: 20,
  F: 10,
  C: 0,
  G: 11,
  D: 22,
  A: 32,
  E: 43,
  B: 54,
  "F#": 65,
  "C#": 76
};

export const INSTRUMENT_IDS = {
  piano: 1,
  harp_pedal: 2,
  harp_non_pedal: 3,
  guitar_classical: 4,
  violin: 5,
  flute: 6
};

export const INSTRUMENTS = [
  {
    id: 6,
    name: "Flute",
    nicerName: "Flute",
    urlName: "flute",
    minGrade: 1,
    maxGrade: 8
  },
  {
    id: 4,
    name: "Guitar (classical)",
    nicerName: "Classical guitar",
    urlName: "guitar_classical",
    minGrade: 1,
    maxGrade: 8
  },
  {
    id: 3,
    name: "Harp (non pedal)",
    nicerName: "Non pedal harp",
    urlName: "harp_non_pedal",
    minGrade: 1,
    maxGrade: 8
  },
  {
    id: 2,
    name: "Harp (pedal)",
    nicerName: "Pedal harp",
    urlName: "harp_pedal",
    minGrade: 1,
    maxGrade: 8
  },
  {
    id: 1,
    name: "Piano",
    nicerName: null,
    urlName: "piano",
    minGrade: 0,
    maxGrade: 8
  },
  {
    id: 5,
    name: "Violin",
    nicerName: "Violin",
    urlName: "violin",
    minGrade: 0,
    maxGrade: 8
  },
];
