import grades from "../data/grades.js";

export const getChosenInstrument = () => {
  return {
    id: localStorage.getItem("instrument.id"),
    name: localStorage.getItem("instrument.name"),
    urlName: localStorage.getItem("instrument.urlName"),
    nicerName: localStorage.getItem("instrument.nicerName")
  };
};

export const getChosenGrade = () => {
  const grade = localStorage.getItem("grade");

  if (grade && grades[grade]) {
    return grades[grade];
  }

  return;
};

export const setChosenInstrument = instrument => {
  localStorage.setItem("instrument.id", instrument.id);
  localStorage.setItem("instrument.name", instrument.name);
  localStorage.setItem("instrument.urlName", instrument.urlName);
  localStorage.setItem(
    "instrument.nicerName",
    instrument.nicerName ? instrument.nicerName : ""
  );
};

export const setChosenGrade = grade => {
  localStorage.setItem("grade", grade);
};

export const removeChosenInstrument = () => {
  localStorage.removeItem("instrument");
};

export const removeChosenGrade = () => {
  localStorage.removeItem("grade");
};
