import React from "react";
import { useTheme } from "@material-ui/core/styles";

import { Box, Toolbar } from "@material-ui/core/";

function TitleBarWrapper({ children }) {
  const theme = useTheme();

  return (
    <Box
      component="header"
      className="row header"
      style={{
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[4]
      }}
    >
      <Toolbar>{children}</Toolbar>
    </Box>
  );
}

export default TitleBarWrapper;
