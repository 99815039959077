import React from "react";

import Logo from "./Logo";

import TitleBarWrapper from "./TitleBarWrapper";

function StartTitleBar() {
  return (
    <TitleBarWrapper>
      <Logo />
    </TitleBarWrapper>
  );
}

export default StartTitleBar;
