import React from "react";
import { Box, Button, Typography } from "@material-ui/core/";

import { useHistory } from "react-router-dom";

import { setChosenInstrument } from "../utils/choices.js";

import grades from "../data/grades.js";

function ChooseGrade({ instrument, chooseGrade }) {
  const history = useHistory();

  // @todo - test this
  if (!instrument) {
    setChosenInstrument(null);

    history.push("/");
    return;
  }

  const buttons = [];

  for (let i = instrument.minGrade; i <= instrument.maxGrade; i++) {
    buttons.push(
      <Button
        key={i}
        className="button"
        variant="outlined"
        color="primary"
        value={i}
        onClick={chooseGrade}
      >
        {grades[i].name}
      </Button>
    );
  }

  return (
    <React.Fragment>
      <Box className="centerTitle" m={2}>
        <Typography variant="h6">
          {instrument.nicerName ? instrument.nicerName : instrument.name},
          great! What grade are you studying for?
        </Typography>
      </Box>
      <Box component="nav" className="chooseButtons">
        {buttons}
      </Box>
    </React.Fragment>
  );
}

export default ChooseGrade;
