import React from "react";
import { Box, Button, Typography } from "@material-ui/core/";

import { INSTRUMENTS } from "../data/constants.js";

function ChooseInstrument({ chooseInstrument }) {
  const choose = event => {
    const instrument = INSTRUMENTS.find(
      // @todo - why has this parseInt become required?
      ins => ins.id === parseInt(event.currentTarget.value, 10)
    );

    chooseInstrument(instrument);
  };

  return (
    <React.Fragment>
      <Box className="centerTitle" m={2}>
        <Typography variant="h6">
          Which musical instrument are you learning?
        </Typography>
      </Box>
      <nav className="chooseButtons">
        {INSTRUMENTS.map(instrument => (
          <Button
            key={instrument.id}
            className="button"
            variant="outlined"
            color="primary"
            value={instrument.id}
            onClick={choose}
          >
            {instrument.name}
          </Button>
        ))}
      </nav>
    </React.Fragment>
  );
}

export default ChooseInstrument;
